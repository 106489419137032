import { css } from "@emotion/react";
import { colors, fontSizes, lineHeights, mediaQuery, spacing } from "@carfax/web-ui/core";

export const styles = {
  footer: () =>
    css({
      width: "100%",
      backgroundColor: colors.black.hex,
      color: colors.white.hex,
      fontSize: fontSizes.body.cat,
      lineHeight: lineHeights.body.cat,
      fontWeight: "bold",
      position: "relative",
      padding: `0 ${spacing.baseUnitMobile2}`,
    }),

  footerContainerContent: () =>
    css({
      margin: "0 auto",
      maxWidth: "1200px",
      padding: `${spacing.baseUnitMobile2} 0`,
      [mediaQuery.desktopOrLarger]: {
        padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile3}`,
      },
    }),

  footerBorder: () =>
    css({
      borderTop: `1px solid ${colors.darkGrey.hex}`,
    }),

  footerNav: () =>
    css({
      marginBottom: `-${spacing.baseUnitMobile3}`,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: "initial",
      },
    }),

  footerMenuItem: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: 0,
      },
    }),

  footerMenuItemLinkWrapper: () =>
    css({
      "&:hover": {
        textDecoration: "none",
      },
    }),

  footerMenuItemLink: () =>
    css({
      color: colors.white.hex,
      cursor: "pointer",
      fontSize: "inherit",
      lineHeight: "inherit",
      fontWeight: "inherit",
      "&:hover": {
        textDecoration: "underline",
      },
      button: {
        color: `${colors.white.hex}!important`,
        cursor: "pointer!important",
        fontSize: "inherit!important",
        lineHeight: "inherit!important",
        fontWeight: "inherit",
        padding: "0!important",
        background: "none!important",
        border: "none!important",
      },
    }),

  footerBottomWrapper: () =>
    css({
      [mediaQuery.desktopOrLarger]: {
        display: "flex",
        justifyContent: "space-between",
      },
      minHeight: "28.5px",
      "& > div": {
        minHeight: "28.5px",
      },
    }),

  text: () =>
    css({
      color: "inherit",
      alignSelf: "center",
      marginBottom: 0,
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      fontWeight: 400,
    }),

  securityIcon: () =>
    css({
      width: "24px",
      height: "24px",
      minWidth: "24px",
      marginRight: spacing.baseUnitMobile2,
    }),

  footerMissionStatement: () =>
    css({
      color: "inherit",
      marginBottom: spacing.baseUnitMobile3,
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      fontWeight: 400,
      [mediaQuery.desktopOrLarger]: {
        marginBottom: 0,
        marginRight: spacing.baseUnitMobile3,
      },
      "& > span": {
        color: "inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
      },
      [mediaQuery.smallerThanTablet]: {
        paddingBottom: spacing.baseUnitMobile2,
        borderBottom: `1px solid ${colors.darkGrey.hex}`,
      },
      [mediaQuery.tabletOrLarger]: {
        marginRight: spacing.baseUnitMobile3,
      },
    }),

  footerMenuColumn: () =>
    css({
      [mediaQuery.desktopOrLarger]: {
        "&> li ~ li": {
          marginTop: spacing.baseUnitMobile3,
        },
      },
    }),

  column: () =>
    css({
      "&&": {
        padding: 0,
      },
    }),

  copyrightGridWrapper: () =>
    css({
      alignSelf: "center",
      gridArea: "copyright",
      [mediaQuery.noneDesktop]: {
        paddingTop: spacing.baseUnitMobile2,
      },
      [mediaQuery.smallerThanTablet]: {
        borderTop: `1px solid ${colors.darkGrey.hex}`,
      },
    }),

  sslGridWrapper: () =>
    css({
      gridArea: "ssl",
      [mediaQuery.tabletOrLarger]: {
        [mediaQuery.noneDesktop]: {
          gridColumn: "1 / -1",
        },
      },
      display: "flex",
      justifyContent: "center",
      [mediaQuery.noneDesktop]: {
        justifyContent: "flex-start",
        paddingBottom: spacing.baseUnitMobile2,
        borderBottom: `1px solid ${colors.darkGrey.hex}`,
      },
    }),

  socialMediaLinksGridWrapper: () =>
    css({
      gridArea: "social_logos",
      [mediaQuery.smallerThanTablet]: {
        margin: `${spacing.baseUnitMobile2} 0`,
      },
      [mediaQuery.tabletOrLarger]: {
        [mediaQuery.noneDesktop]: {
          paddingTop: spacing.baseUnitMobile2,
        },
      },
    }),

  gridContainer: () =>
    css({
      display: "grid",
      width: "100%",
      justifyContent: "space-between",
      gridAutoRows: "minmax(min-content, max-content)",
      [mediaQuery.smallerThanTablet]: {
        gridTemplateColumns: "100%",
        gridTemplateAreas: `
               'ssl . .'
               'social_logos . .'
               'copyright . .'
            `,
      },
      [mediaQuery.desktopOrLarger]: {
        gridTemplateAreas: `
            '. . .'
            'copyright ssl social_logos'
            '. . .'
            `,
      },
      [mediaQuery.tabletOrLarger]: {
        [mediaQuery.noneDesktop]: {
          gridTemplateAreas: `
                'ssl . .'
                'copyright . social_logos'
                `,
        },
      },
    }),
};
