export const UrlQueryParameters = {
  TRANSACTION_ID: "transactionId",
  REPORT_ID: "reportId",
  VIN: "vin",
  ENCRYPTED_VINREG: "encrypted_vinreg",
  LANGUAGE: "lang",
  EMAIL: "email",
  UUID: "uuid",
  REACPTCHA_V2: "g-recaptcha-token",
  RECAPTCHA_V3: "recaptchaToken",
  SET_PASSWORD_TOKEN: "token",
  REDIRECT: "redirect",
  COUNTRY: "country",
  VIES_RESULT: "viesResult",
  PACKAGE_NAME: "packageName",
  ERROR_KEY: "error",
};
