import React, { useState, useEffect, useRef } from "react";
import { Icon, Icons } from "../../elements/Icon";
import { styles } from "./ModalMounted.style";

export interface ModalMountedProps {
  isOpen: boolean;
  onClose: React.MouseEventHandler;
  children?: React.ReactNode;
}

export const ModalMounted: React.FC<ModalMountedProps> = ({ isOpen, onClose, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    } else {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [isOpen]);

  const closeModal = (e) => {
    setIsAnimating(false);
    if (isOpen) {
      timerRef.current = window.setTimeout(() => {
        onClose(e);
      }, 250);
    } else {
      onClose(e);
    }
  };

  return (
    <div css={styles.modalWrapper(isOpen)} onClick={closeModal}>
      <div css={styles.contentWrapper(isAnimating)} onClick={(e) => e.stopPropagation()}>
        <div css={styles.IconButton} onClick={closeModal}>
          <Icon icon={Icons.closeBlue} />
        </div>
        {children}
      </div>
    </div>
  );
};
