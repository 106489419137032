import { css, keyframes } from "@emotion/react";
import { mediaQuery, spacing, colors } from "../../core/styles";

const visibilityAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const styles = {
  modalWrapper: (isOpen: boolean) =>
    css({
      display: isOpen ? "block" : "none",
      backgroundColor: colors.darkTransparent.hex,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: "100vh",
      animation: `${visibilityAnimation} 250ms ease`,
      animationFillMode: "forwards",
      overflowY: "hidden",
      zIndex: 1200,
    }),
  contentWrapper: (isAnimating: boolean) =>
    css({
      maxWidth: "max-content",
      width: `calc(100% - ${spacing.baseUnitMobile2} * 2)`,
      maxHeight: `calc(100% - ${spacing.baseUnitMobile2} * 2)`,
      overflow: "auto",
      padding: `${spacing.baseUnitMobile6} ${spacing.baseUnitMobile2}`,
      opacity: isAnimating ? 1 : 0,
      transition: "opacity 0.25s ease-in-out",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      zIndex: 999,
      [mediaQuery.tabletOrLarger]: {
        padding: spacing.baseUnitMobile6,
      },
    }),
  IconButton: () =>
    css({
      position: "absolute",
      top: "4px",
      right: "4px",
      padding: "12px",
      cursor: "pointer",
      boxSizing: "content-box",
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      "&&: hover": {
        backgroundColor: colors.splitterGrey.hex,
      },
      "&&: active": {
        backgroundColor: colors.backgroundGrey.hex,
      },
    }),
};
