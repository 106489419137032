import { css } from "@emotion/react";
import { colors, mediaQuery, spacing } from "@carfax/web-ui/core";

export const styles = {
  layoutWrapper: (hasGrayBackground: boolean) =>
    css({
      backgroundColor: hasGrayBackground ? colors.backgroundGrey.hex : "initial",
      display: "flex",
      minHeight: "100vh",
      flexDirection: "column",
    }),
  divLayout: () =>
    css({
      flex: "1 1 auto",
    }),
  skipToMainContentLink: () =>
    css({
      position: "absolute",
      padding: `0 ${spacing.baseUnitMobile1}`,
      background: colors.white.hex,
      zIndex: 0,
      borderRadius: "4px",
      left: spacing.baseUnitMobile2,
      ":focus": {
        zIndex: 200,
        top: spacing.baseUnitMobile9,
        [mediaQuery.largerThanMobile]: {
          top: "88px",
        },
      },
      display: "none",
      [mediaQuery.desktopOrLarger]: {
        display: "initial",
      },
    }),
};

export const globalPageStyle = {
  body: (showReCaptchaV3: boolean) =>
    css({
      ".grecaptcha-badge": {
        visibility: showReCaptchaV3 ? "visible" : "hidden",
      },
    }),
};
