import { DropdownItem } from "@carfax/web-ui/components/Navbar";
import { useTranslation } from "app";
import { useTranslatableRoutes } from "domain/Routing/hooks/useTranslatableRoutes";
import { useAvailableLanguage } from "hooks/useAvailableLanguage";
import { languageConfig } from "i18n/language-config";
import { useMemo } from "react";
import { linkUtil } from "utils/i18n/link-util";

type TTranslatedSlugs = Record<string, string>;

const useLanguagesList = (translatedSlugs?: TTranslatedSlugs): DropdownItem[] => {
  const { t, i18n } = useTranslation(["common"]);
  const availableLanguage = useAvailableLanguage();
  const routesLang = useTranslatableRoutes(availableLanguage);
  return useMemo(() => {
    const language = languageConfig.mapLocaleToLanguageShort(i18n.language);
    return availableLanguage
      .map((languageOption) => {
        const locale = languageConfig.mapLanguageToLocale(languageOption);
        let route = routesLang[languageOption];
        if (translatedSlugs?.[locale]) {
          route += `/${translatedSlugs[locale]}`;
        }
        return {
          label: t(`dropdown.${languageOption}`),
          action: { href: linkUtil.i18nLinkWithLanguage(route, languageOption) },
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label, language));
  }, [i18n, availableLanguage, routesLang, translatedSlugs]);
};

export { useLanguagesList };
