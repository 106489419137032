import { LanguageResolver } from "../../../i18n/language-resolver";
import { languageConfig } from "../../../i18n/language-config";
import { customRouteMap, RoutesKeys } from "../customRouteMap";

type Routes = {
  [Key in RoutesKeys]?: string;
};

const getCustomRouteForLocale = (slug: string, locale: string): string => {
  const lang = languageConfig.mapLocaleToLanguageShort(locale);
  const fallbackRoute = customRouteMap[slug].defaultRoute || "";

  return customRouteMap[slug] && customRouteMap[slug].routes[lang] ? customRouteMap[slug].routes[lang] : fallbackRoute;
};

const removeDynamicPartFromPath = (customRoute) => {
  const homePageRoute = "/";
  if (!customRoute) {
    return homePageRoute;
  }
  const regex = /\[[a-zA-Z]+\]/g;
  const matches = customRoute.match(regex);
  return matches ? customRoute.replace(regex, "") : customRoute;
};

const getCustomRouteForLanguage = (slug: string, lang: string): string => {
  const homePageRoute = "/";

  if (!customRouteMap[slug]) {
    return homePageRoute;
  }

  return customRouteMap[slug].routes
    ? removeDynamicPartFromPath(customRouteMap[slug].routes[lang]) || homePageRoute
    : removeDynamicPartFromPath(customRouteMap[slug].defaultRoute);
};

const getRoutePartFromPath = (pathName: string): string => {
  const regex = /(\/)(?:[a-zA-Z]{2}|\[lang\])(\/)/g;
  const matches = pathName.match(regex);
  return matches ? "/" + pathName.replace(regex, "") : pathName;
};

const getQueryPartFromPath = (path: string): string => {
  const queryDelimeter = "?";
  const parts: string[] = path?.split(queryDelimeter);

  return parts?.length > 0 && parts[1]?.length > 0 ? queryDelimeter.concat(parts[1]) : "";
};

const getLanguagePartFromPath = (path: string): string => {
  const pattern = /(\/)(?:[a-zA-Z]){2}(\/)/g;
  const match = path.match(pattern);
  return match ? match[0].replace(/\//g, "") : languageConfig.getFallbackLanguage();
};

const getMarkerPartFromPath = (path: string): string => {
  const pattern = /(?:[?&])marker=(?:(true|false))/g;
  const match = path.match(pattern);
  return match ? match[0].split("=")[1] : undefined;
};

const findSlugForCustomPath = (pathName: string): string => {
  const lang = getLanguagePartFromPath(pathName);
  const route = getRoutePartFromPath(pathName);

  return Object.keys(customRouteMap).find(
    (slug) =>
      customRouteMap[slug] &&
      customRouteMap[slug].routes &&
      customRouteMap[slug].routes[lang] &&
      route.indexOf(customRouteMap[slug].routes[lang]) >= 0
  );
};

const getRoutes = (lang?): Routes => {
  const langToCheck = lang ? lang : LanguageResolver.getCurrentLanguageShort();

  return Object.keys(customRouteMap).reduce(
    (acc, slug) => ({ ...acc, [slug]: getCustomRouteForLanguage(slug, langToCheck) }),
    {}
  );
};

const isHomepage = (routeAsPath): boolean => {
  const languages = languageConfig.getLanguages();
  const defaultHomepagePath = "/";
  const homepageLangPaths = languages.map((lang) => languageConfig.mapLanguageToLanguageUrlSubpath(lang));
  const homepagePaths = [defaultHomepagePath, ...homepageLangPaths];
  const routeAsPathNoParams = routeAsPath.split("?")[0];
  return homepagePaths.includes(routeAsPathNoParams);
};
const isCareers = (routeAsPath): boolean => {
  return routeAsPath == "/careers" || routeAsPath == "de/careers";
};
const isPricing = (routeAsPath): boolean => {
  return findSlugForCustomPath(routeAsPath) == "pricing";
};

export const routesUtil = {
  getCustomRouteForLocale,
  getCustomRouteForLanguage,
  getRoutePartFromPath,
  getLanguagePartFromPath,
  getMarkerPartFromPath,
  findSlugForCustomPath,
  getRoutes,
  getQueryPartFromPath,
  isHomepage,
  isCareers,
  isPricing,
};
