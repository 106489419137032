const isBrowser = typeof window !== "undefined" && typeof window.document !== "undefined";

const scrollToElement = (id: string, verticalAlignment?: "start" | "center" | "end" | "nearest"): void => {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: verticalAlignment || "start" });
  }
};

const isSafari = (): boolean => {
  if (!isBrowser) return false;
  const userAgent = window.navigator.userAgent;

  return (
    /Safari/.test(userAgent) &&
    !/Chrome/.test(userAgent) &&
    !/Edg/.test(userAgent) &&
    !/OPR/.test(userAgent) &&
    !/Firefox/.test(userAgent)
  );
};

export const browserUtil = {
  isBrowser,
  scrollToElement,
  isSafari,
};
