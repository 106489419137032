export const customRouteMap = {
  blog: {
    defaultRoute: "/blog",
  },
  blogPage: {
    defaultRoute: "/blog/[postName]",
  },
  blogCategory: {
    defaultRoute: "category/[categoryName]",
  },
  business: {
    defaultRoute: "/business",
  },
  careers: {
    defaultRoute: "/careers",
  },
  partnerLandingPage: {
    defaultRoute: "/[partnerLandingPageName]",
  },
  privacyPolicyCheckout: {
    defaultRoute: "/privacy-policy-checkout",
  },
  preview: {
    defaultRoute: "/preview",
  },
  reportPreview: {
    defaultRoute: "/report-preview",
  },
  previewPage: {
    defaultRoute: "/preview-page",
  },
  account: {
    defaultRoute: "/account",
  },
  accountLogin: {
    defaultRoute: "/account/login",
  },
  accountSettings: {
    defaultRoute: "/account/settings",
  },
  accountPasswordReset: {
    defaultRoute: "/account/password-reset",
  },
  accountReports: {
    defaultRoute: "/account/reports",
  },
  faq: {
    defaultRoute: "/faq",
  },
  contactThankYou: {
    defaultRoute: "/contact/thank-you",
  },
  paymentThankYou: {
    defaultRoute: "/payment/thank-you",
  },
  packages: {
    defaultRoute: "/packages",
  },
  redeemCredit: {
    defaultRoute: "/report-credit",
  },
  reactivate: {
    defaultRoute: "/reactivate",
  },
  termsAndConditions: {
    defaultRoute: "/terms-and-conditions",
    routes: {
      en: "/terms-and-conditions",
      nl: "/algemene-voorwaarden",
      de: "/allgemeine-geschaeftsbedingungen",
      it: "/termini-e-condizioni-di-utilizzo",
      pl: "/warunki-handlowe",
      sl: "/pogoji-nakupa-in-uporabe",
      fr: "/conditions-generales",
      ru: "/terms-and-conditions",
      es: "/terminos-y-condiciones",
      sv: "/villkor",
    },
  },
  imprint: {
    defaultRoute: "/legal-information",
    routes: {
      en: "/legal-information",
      nl: "/impressum",
      de: "/impressum",
      it: "/avvertenze-legali",
      pl: "/dane-identyfikacyjne-dostawcy",
      sl: "/pravno-obvestilo",
      fr: "/mentions-legales",
      ru: "/legal-information",
      es: "/informacion-legal",
      sv: "/juridisk-information",
    },
  },
  contact: {
    defaultRoute: "/contact",
    routes: {
      en: "/contact",
      nl: "/contact",
      de: "/kontakt",
      pl: "/kontakt",
      sl: "/kontakt",
      it: "/contatto",
      fr: "/contact",
      ru: "/contact",
      es: "/contacto",
      sv: "/kontakt",
    },
  },
  privacyNoticeForLinkedinFanPage: {
    defaultRoute: "/privacy-notice-for-linkedin-fan-page",
  },
  privacyNoticeForCarOwners: {
    defaultRoute: "/privacy-notice-for-car-owners",
  },
  privacyNoticeApplicants: {
    defaultRoute: "/privacy-notice-applicants",
    routes: {
      en: "/privacy-notice-applicants",
      de: "/privacy-notice-applicants",
    },
  },
  privacyHub: {
    defaultRoute: "/privacy-hub",
  },
  privacyNoticeForWhistleBlowers: {
    defaultRoute: "/privacy-notice-for-whistle-blowers",
  },
  privacyNoticeForBusinessCustomers: {
    defaultRoute: "/privacy-notice-for-business-customers",
  },
  privacy: {
    defaultRoute: "/privacy-notice",
    routes: {
      en: "/privacy-notice",
      nl: "/privacy",
      fr: "/privacy",
      de: "/datenschutzhinweise",
      it: "/informativa-sulla-privacy",
      pl: "/polityka-prywatnosci",
      sl: "/pravilnik-o-zasebnosti",
      ru: "/privacy-notice",
      es: "/avisos-de-privacidad",
      sv: "/sekretesspolicy",
    },
  },
  press: {
    defaultRoute: "/press",
    routes: {
      en: "/press",
      nl: "/pers",
      de: "/presse",
      fr: "/presse",
      it: "/news",
      pl: "/aktualnosci",
      sl: "/novice",
      ru: "/press",
      es: "/prensa",
      sv: "/press",
    },
  },
  vhr: {
    defaultRoute: "/vhr",
  },
  freeVinDecoder: {
    defaultRoute: "/free-vin-decoder",
    routes: {
      en: "/free-vin-decoder",
      nl: "/chassisnummer-check",
      de: "/kostenloser-vin-decoder",
      fr: "/niv-decoder-gratuit",
      it: "/verifica-numero-telaio",
      pl: "/dekoder-vin",
      sl: "/brezplacni-vin-dekoder",
      ru: "/proverka-vin-koda",
      es: "/comprobar-bastidor-vehiculo",
      sv: "/gratis-identitetscheck-for-begagnade-bilar",
    },
  },
  bmwVinDecoder: {
    defaultRoute: "/bmw-vin-decoder",
    routes: {
      en: "/bmw-vin-decoder",
      nl: "/bmw-vin-decoder",
      de: "/bmw-vin-decoder",
      fr: "/bmw-vin-decoder",
      it: "/bmw-vin-decoder",
      pl: "/bmw-vin-decoder",
      sl: "/bmw-vin-decoder",
      ru: "/bmw-vin-decoder",
      es: "/bmw-vin-decoder",
      sv: "/bmw-vin-decoder",
    },
  },
  mileageCheck: {
    defaultRoute: "/mileage-check",
    routes: {
      en: "/mileage-check",
      nl: "/nap-check-carfax",
      de: "/kilometerstand-pruefen",
      fr: "/verifier-kilometrage",
      it: "/controllo-del-chilometraggio",
      pl: "/sprawdzenie-przebiegu",
      sl: "/preverjanje-kilometrov",
      ru: "/proverka-probega",
      es: "/comprobar-el-kilometraje",
      sv: "/kontroll-av-kilometerstand",
    },
  },
  carServiceHistory: {
    defaultRoute: "/car-service-history",
    routes: {
      en: "/car-service-history",
      nl: "/onderhoudshistorie",
      de: "/importauto-servicehistorie",
      fr: "/verifiez-historique-entretien",
      it: "/verificare-storico-revisioni-auto",
      pl: "/sprawdz-przeglady-pojazdu",
      ru: "/istoriya-to",
      es: "/inspecciones-tecnicas-del-vehiculo",
      sv: "/servicehistorik-bil",
      sl: "/zgodovina-pregleda-vozila",
    },
  },
  freeImportCheck: {
    defaultRoute: "/free-import-check",
    routes: {
      en: "/free-import-check",
      nl: "/import-auto-checken",
      de: "/gratis-import-check",
      fr: "/verification-gratuite-importations",
      it: "/controllo-importazioni-auto-gratuito",
      pl: "/bezplatne-sprawdzenie-pochodzenia-pojazdu",
      sl: "/brezplacno-preverjanje-uvoza",
      ru: "/besplatnaya-proverka-na-import",
      es: "/comprobar-importacion-coche-gratis",
      sv: "/kostnadsfri-importkontroll",
    },
  },
  pricing: {
    defaultRoute: "/pricing",
    routes: {
      en: "/pricing",
      nl: "/prijzen",
      de: "/preise",
      fr: "/prix",
      it: "/prezzi",
      pl: "/cennik",
      sl: "/cene",
      ru: "/tseny",
      sv: "/pris",
      es: "/precios",
    },
  },
  sampleReport: {
    defaultRoute: "/vehicle-history-report ",
    routes: {
      en: "/vehicle-history-report",
      nl: "/voertuighistorierapport",
      de: "/gebrauchtwagenhistorie",
      fr: "/historique-voiture",
      it: "/storia-dell-auto-usata",
      pl: "/historia-pojazdu",
      sl: "/zgodovina-vozila",
      ru: "/otcet-ob-istorii-avtomobilja",
      sv: "/fordonshistorik",
      es: "/informe-vehiculo",
    },
  },
  stolenCarCheck: {
    defaultRoute: "/stolen-car-check",
    routes: {
      en: "/stolen-car-check",
      de: "/autodiebstahl-pruefen",
      es: "/comprobar-si-un-coche-es-robado",
      nl: "/gestolen-auto-check",
      pl: "/weryfikacja-zgloszen-kradziezy",
      sv: "/kontroll-av-stulen-bil",
    },
  },
  ownershipHistory: {
    defaultRoute: "/check-car-ownership-history",
    routes: {
      en: "/check-car-ownership-history",
      de: "/anzahl-vorbesitzer-ermitteln",
      es: "/historial-de-propietarios",
      nl: "/kenteken-check-eigenaar",
      pl: "/sprawdz-poprzednich-wlascicieli-auta",
      sv: "/vem-ager-bilen",
    },
  },
  damageAccidentCheck: {
    defaultRoute: "/check-accident-damage-history ",
    routes: {
      en: "/check-accident-damage-history",
      es: "/comprobar-historial-accidentes-coche",
      it: "/acquisto-auto-incidentata-come-controllare",
      nl: "/check-schadeverleden-auto",
      pl: "/sprawdz-historie-szkod-i-wypadkow",
      sv: "/krockad-bil",
    },
  },
  licensePlateCheck: {
    defaultRoutes: "/",
    routes: {
      es: "/comprobar-matricula",
      it: "/controllo-targa-auto",
      nl: "/kenteken-checken",
      sv: "/sok-bilregistret",
    },
  },
  RegistrationDateCheck: {
    defaultRoutes: "/",
    routes: {
      es: "/comprobar-fecha-matriculacion",
      it: "/controllare-anno-immatricolazione",
    },
  },
  carData: {
    defaultRoutes: "/",
    routes: {
      nl: "/autogegevens",
      sv: "/fordonsuppgifter",
    },
  },
  inspectionHistoryCheck: {
    defaultRoutes: "/",
    routes: {
      nl: "/apk-check",
      sv: "/kontrollera-bilens-besiktningshistorik",
      sl: "/zgodovina-pregleda-vozila",
    },
  },
  bolloCalculator: {
    defaultRoutes: "/calcolare-bollo-auto",
    routes: {
      it: "/calcolare-bollo-auto",
    },
  },
  businessSignUp: {
    defaultRoute: "/business/sign-up",
  },
  businessDocumentUpload: {
    defaultRoute: "/business/document-upload",
  },
  businessAccount: {
    defaultRoute: "/business/account",
  },
};
export type RoutesKeys = keyof typeof customRouteMap;
