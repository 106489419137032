import { action, makeObservable, observable } from "mobx";
import { Toggles } from "../../server/feature-toggles/toggles";
import { defaultToggles } from "../utils/featureTogglesUtils";
class LayoutStore {
  @observable
  public showReCaptchaV3: boolean;

  @observable
  public isTrackingAllowed: boolean;

  @observable
  public toggles: Toggles;

  @observable
  public isReCaptchaV2ModalVisible: boolean;

  constructor() {
    makeObservable(this);
    this.toggleReCaptchaVisibility = this.toggleReCaptchaVisibility.bind(this);
    this.showReCaptchaBadge = this.showReCaptchaBadge.bind(this);
    this.hideReCaptchaBadge = this.hideReCaptchaBadge.bind(this);
    this.setIfTrackingAllowed = this.setIfTrackingAllowed.bind(this);
    this.showReCaptchaV3 = false;
    this.toggles = defaultToggles;
    this.isReCaptchaV2ModalVisible = false;
    this.isTrackingAllowed = false;
  }

  @action
  public showReCaptchaBadge() {
    this.toggleReCaptchaVisibility(true);
  }

  @action
  public hideReCaptchaBadge() {
    this.toggleReCaptchaVisibility(false);
  }
  @action
  public setToggles(newToggles: Toggles): void {
    this.toggles = newToggles;
  }

  private toggleReCaptchaVisibility(recaptchaVisible: boolean): void {
    this.showReCaptchaV3 = recaptchaVisible;
  }

  @action
  public showReCaptchaV2Modal() {
    this.isReCaptchaV2ModalVisible = true;
  }

  @action
  public hideReCaptchaV2Modal() {
    this.isReCaptchaV2ModalVisible = false;
  }

  @action
  public setIfTrackingAllowed(state: boolean) {
    this.isTrackingAllowed = state;
  }
}

export default new LayoutStore();
export { LayoutStore };
