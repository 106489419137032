import { Global } from "@emotion/react";
import { resetStyles, typographyStyles, globalStyles } from "@carfax/web-ui/core";
import { observer, useTranslation } from "app";
import { useStores } from "hooks";
import { HeaderProps } from "components/Layout/Header";
import Content from "components/Layout/Content";
import useRouterScroll from "hooks/useRouterScroll";
import { globalPageStyle, styles } from "./Layout.style";
import Footer from "./Footer";
import { useLanguagesList } from "hooks/useLanguagesList";
import { DropdownItem } from "@carfax/web-ui/components/Navbar";
import { PropsWithChildren, useEffect } from "react";
import CaptchaV2 from "./CaptchaV2";
import Alerts from "components/Layout/Alerts";
import dynamic from "next/dynamic";
import { Toggles } from "../../../server/feature-toggles/toggles";
import { DefaultSampleReport } from "contentful/PageModularModule";
import { Roboto } from "next/font/google";
import { useRouterFocusReset } from "../../hooks/useRouterFocusReset";
import { ModalMounted } from "@carfax/web-ui/components/ModalMounted";

const Header = dynamic<HeaderProps>(() => import("components/Layout/Header"));

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  display: "swap",
  preload: false,
});

interface IProps {
  // Todo: Use typings of Contentful content model
  defaultSampleReports: DefaultSampleReport;
  headerMenu?: any;
  hideNavInHeader?: boolean;
  showOnlyLoginInNavbar?: boolean;
  loginRedirect?: string;
  hasGrayBackground?: boolean;
  hideRecaptcha?: boolean;
  translatedSlugs?: Record<string, string>;
  toggles?: Toggles;
}
const Layout: React.FC<PropsWithChildren<IProps>> = observer((props) => {
  const {
    children,
    hideNavInHeader,
    hasGrayBackground,
    translatedSlugs,
    toggles,
    showOnlyLoginInNavbar,
    loginRedirect,
    defaultSampleReports,
  } = props;
  const { t } = useTranslation(["common"]);
  const { layoutStore, sampleReportStore } = useStores();
  const { showReCaptchaV3 } = layoutStore;
  useRouterFocusReset();
  useRouterScroll();
  const languageOptions: DropdownItem[] = useLanguagesList(translatedSlugs);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          for (const registration of registrations) {
            registration.unregister();
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (toggles) {
      layoutStore.setToggles(toggles);
    }
  }, [toggles]);

  useEffect(() => {
    sampleReportStore.setSampleReport(defaultSampleReports);
  }, []);

  return (
    <div css={styles.layoutWrapper(hasGrayBackground)} className={roboto.className}>
      <Global styles={[resetStyles, typographyStyles, globalStyles, globalPageStyle.body(showReCaptchaV3)]} />
      <a href="#main-content" className="skip-link" css={styles.skipToMainContentLink}>
        {t("header.menu.skipToMainContent")}
      </a>
      <Header
        hideNavInHeader={hideNavInHeader}
        languageOptions={languageOptions}
        showOnlyLoginInNavbar={showOnlyLoginInNavbar}
        loginRedirect={loginRedirect}
      />
      <div css={styles.divLayout()} id="main-content">
        <Alerts />
        <Content>{children}</Content>
      </div>
      <Footer hidePaymentLogosInFooter={hideNavInHeader} />
      <ModalMounted
        key={`recaptcha-v2-modal-${layoutStore.isReCaptchaV2ModalVisible}`}
        isOpen={layoutStore.isReCaptchaV2ModalVisible}
        onClose={() => layoutStore.hideReCaptchaV2Modal()}
      >
        <CaptchaV2 id="vinsearch-recaptcha" />
      </ModalMounted>
    </div>
  );
});

export default Layout;
